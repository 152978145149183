import axios from "axios";
import { admin_server } from "servers";

export const logout = () => {
  return axios
    .get(`${admin_server}/logout`, {
      withCredentials: true,
    })
    .then((response) => {
      return {
        message: response.data.message,
      };
    })
    .catch((error) => {
      const axiosError = error;
      return {
        error: axiosError.message,
      };
    });
};


export const deleteUserAccount = () => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${admin_server}/delete-user`, { withCredentials: true })
      .then((response) => {
        console.log(response.data.message);
        resolve(response.data.message);
      })
      .catch((err) => {
        console.log(err.message);
        reject(err); 
      });
  });
};
