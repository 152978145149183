import { getAllUserEntries, deleteUserEntry } from "actions/user";
import ResultDisplay from "./components/ResultDisplay";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";

const UsersEntry = () => {
  const [entries, setEntries] = useState([]);
  const [currentEntryIndex, setCurrentEntryIndex] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false); // State to track deletion process

  useEffect(() => {
    getAllUserEntries()
      .then((entries) => {
        setEntries(entries);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  const handleNextEntry = () => {
    if (currentEntryIndex < entries.length - 1) {
      setCurrentEntryIndex(currentEntryIndex + 1);
    }
  };

  const handlePreviousEntry = () => {
    if (currentEntryIndex > 0) {
      setCurrentEntryIndex(currentEntryIndex - 1);
    }
  };

  const handleDeleteEntry = async () => {
    const currentEntry = entries[currentEntryIndex];
    const entryId = currentEntry._id; // Assuming "_id" is the key for the entry ID

    setIsDeleting(true); // Set loading state to true before the delete operation
    try {
      await deleteUserEntry(entryId);
      // Update state to remove deleted entry from list
      const updatedEntries = entries.filter(
        (entry, index) => index !== currentEntryIndex
      );
      setEntries(updatedEntries);
      if (currentEntryIndex > 0) {
        setCurrentEntryIndex(currentEntryIndex - 1);
      }
      toast.success("User Entry deleted successfully");
      console.log("Entry deleted successfully");
    } catch (err) {
      toast.error("User Entry delete failed");
      console.log(err.message);
    } finally {
      setIsDeleting(false); // Set loading state to false after the operation is completed
    }
  };

  const formatKey = (key) => {
    return key
      .replace(/_/g, " ")
      .replace(/\b\w/g, (match) => match.toUpperCase());
  };

  const excludedKeys = ["updatedAt", "createdAt", "__v"];
  const currentEntry = entries[currentEntryIndex];

  return (
    <div className="mt-3 grid h-full grid-cols-1 gap-5">
      <div className="col-span-1 h-fit w-full">
        {entries.length > 0 ? (
          <div>
            {/* Delete button */}
            <div className="mb-4">
              <button
                onClick={handleDeleteEntry} // Attach delete function to button
                className={`rounded bg-red-500 px-4 py-2 text-white hover:bg-red-600 ${
                  isDeleting ? "cursor-not-allowed opacity-50" : ""
                }`}
                disabled={isDeleting} // Disable button when deleting
              >
                {isDeleting ? "Deleting..." : "Delete Account"}{" "}
                {/* Show loading text */}
              </button>
            </div>

            <ResultDisplay
              entries={entries}
              currentEntryIndex={currentEntryIndex}
              currentEntry={currentEntry}
              formatKey={formatKey}
              excludedKeys={excludedKeys}
              handleNextEntry={handleNextEntry}
              handlePreviousEntry={handlePreviousEntry}
            />
          </div>
        ) : (
          <p className="dark:text-white">No entries found</p>
        )}
      </div>
    </div>
  );
};

export default UsersEntry;
